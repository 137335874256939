import { Box, Typography, Divider } from '@mui/material';
import { OATypography } from '../../../../components/widgets';
import OrderInvoice from '../../OrderInvoice';
import {
  SMBCount,
  getRefundTable,
  getRoundTripTable,
  getSMBChargesTable,
  getTableData
} from '../PaymentSummaryTables/customerTables';
import { ReactComponent as ErrorIcon } from '../../../../assets/icons/error.svg';
import { ReactComponent as SuccessIcon } from '../../../../assets/icons/success.svg';
import { useState } from 'react';
import { CustomTableAccordion } from '../FlightTabHelpers/AccordianTable';
import { ReactComponent as DoubleArrowIcon } from '../../../../assets/icons/DoubleArrows.svg';
import { ReactComponent as SourceDestIcon } from '../../../../assets/icons/source-DestArrow.svg';
import { Link } from 'react-router-dom';

interface ITravelDetails {
  data: any;
  isLoading: boolean;
}

export default function Customer(props: ITravelDetails) {
  const [accordionStates, setAccordionStates] = useState({
    onwardExpand: true,
    returnExpand: true,
    smbOnwardExpand: true
  });

  let orgCode = props?.data?.userOrganization?.organizationCode;
  // Generalized handler function
  const handleAccordionChange = (accordion: string, isExpanded: boolean) => {
    setAccordionStates(prevStates => ({
      ...prevStates,
      [accordion]: isExpanded
    }));
  };

  let onward = props?.data?.flights?.find((onward: any) => onward?.departureType === 'ONWARD');
  let onwardDeparture = onward?.segments?.find((segment: any) => segment?.segmentId === 1);
  let onwardArrival = onward?.segments?.find(
    (segment: any) => segment?.segmentId === onward?.segments?.length
  );
  let returnData = props?.data?.flights?.find((obj: any) => obj?.departureType === 'RETURN');
  let returnArrival = returnData?.segments?.find(
    (segment: any) => segment?.segmentId === onward?.segments?.length + 1
  );
  let onwardBreakup = props?.data?.orderAmount?.flightBasedBreakUp?.find(
    (onwardBreakup: any) => onwardBreakup?.flightId === onward?.id
  );

  let returnBreakup = props?.data?.orderAmount?.flightBasedBreakUp?.find(
    (returnBreakup: any) => returnBreakup?.flightId === returnData?.id
  );
  const onwardCount = SMBCount(onward);
  const returnCount = SMBCount(returnData);
  const seatCount = onwardCount?.seatCount + returnCount?.seatCount;
  const mealCount = onwardCount?.mealCount + returnCount?.mealCount;
  const baggageCount = onwardCount?.baggageCount + returnCount?.baggageCount;
  let smbCount = { seatCount, mealCount, baggageCount };
  const tableData = getTableData(props, onward);
  const roundTripTableData = getRoundTripTable(
    props,
    onward,
    onwardBreakup,
    returnData,
    returnBreakup
  );
  const refundTableData =
    props?.data?.orderAmount?.paymentSummary?.refundSummary && getRefundTable(props);
  const SMBChargesData: any = getSMBChargesTable(props, smbCount);

  const secondHeaderIndex = roundTripTableData?.findIndex((obj: any) => obj?.secondHeader === true);
  const cancellatonCover = props?.data?.downloadAttachment?.addOnServices?.find((obj: any) => obj?.type === "CFAR_FLIGHT_INSURANCE")
  const odsSelfLinks = props?.data?.odsSelfLinks?.find((obj:any) => obj?.ancillaryType === "INSURANCE")
  const coinDiscount = (tableData ? tableData : roundTripTableData)?.find((obj: any) => obj?.label.includes("coins discount"))
  const totalFareIndex = tableData?.findIndex((obj: any) => obj?.label === 'Total airfare');
  const newTableData = tableData.slice(0, totalFareIndex);
  const onwardTable = roundTripTableData?.slice(0, 4);
  let festiveDiscounts = props?.data?.orderAmount?.discountTotalAmount?.discountBreakUps?.find((obj: any) => obj?.type)
  let addOn = props?.data?.orderAmount?.addOnServicesAmount?.addonServiceBreakUps?.map((obj: any) => obj.type)
  if (!addOn) {
    addOn = [];
  } else if (typeof addOn === 'object' && !Array.isArray(addOn)) {
    addOn = [];
  }
  if (festiveDiscounts) {
    addOn.push(festiveDiscounts.type);
  } 
  const returnTable = roundTripTableData?.slice(secondHeaderIndex, roundTripTableData?.length - ((addOn?.length > 0 && coinDiscount) ? 4 + addOn?.length : (coinDiscount || addOn) ? (4 + (addOn?.length - 1)) : 3));
  const sourceTable = tableData ? tableData : roundTripTableData;
  const TripType = () => {
    return (
      <>
        {props?.data?.flights?.length <= 1 ? (
          <Box display='flex' gap='8px' alignItems='center'>
            <OATypography variant='body1'>{onwardDeparture?.departure?.airport?.city}</OATypography>
            <SourceDestIcon height={16} />
            <OATypography variant='body1'>{onwardArrival?.arrival?.airport?.city}</OATypography>
          </Box>
        ) : (
          <Box display='flex' gap='8px' alignItems='center'>
            <OATypography variant='body1'>{onwardDeparture?.departure?.airport?.city}</OATypography>
            <DoubleArrowIcon height={16} />
            <OATypography variant='body1'>{returnArrival?.departure?.airport?.city}</OATypography>
          </Box>
        )}
      </>
    );
  };
  return (
    <Box>
      {props?.data?.orderAmount?.paymentSummary?.coinsEarned > 0 &&
        (!orgCode || orgCode?.toLowerCase()?.includes('niyo')) && (
          <Box
            display='flex'
            height='40px'
            borderRadius='10px'
            alignItems='center'
            justifyContent='start'
            margin='16px 16px 0px 16px'
            sx={{
              backgroundColor: 'success.light'
            }}>
            <SuccessIcon style={{ marginLeft: '10px', color: 'success.main' }} />
            <Box display='flex' alignItems='center'>
              <OATypography
                variant='subtitle2'
                sx={{
                  fontSize: '12px',
                  marginLeft: '10px'
                }}>
                {props?.data?.orderAmount?.paymentSummary?.coinsEarned}
              </OATypography>
              <OATypography
                variant='body2'
                sx={{ fontSize: '12px', lineHeight: '16px', paddingLeft: '4px' }}>
                Niyo Global Coins earned on this trip.
              </OATypography>
            </Box>
          </Box>
        )}
      <div className='card-body py-0'>
        {(props?.data?.flights?.length <= 1 ||
          props?.data?.orderAmount?.flightBasedBreakUp?.length < 1) && (
            <>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '16px 8px'
                }}>
                <Typography
                  variant='subtitle2'
                  sx={{ fontSize: '12px', lineHeight: '16px', color: 'grey.400' }}>
                  PRODUCT
                </Typography>
                <Typography
                  variant='subtitle2'
                  sx={{
                    fontSize: '12px',
                    lineHeight: '16px',
                    color: 'grey.400'
                  }}>
                  AMOUNT
                </Typography>
              </Box>
              <Divider />
              <CustomTableAccordion
                CustomLabel={TripType}
                tableData={newTableData}
                value={newTableData[0]?.value ?? 'N/A'}
                callback={(event: any, isExpanded: boolean) =>
                  handleAccordionChange('onwardExpand', isExpanded)
                }
                expand={accordionStates?.onwardExpand}
                flightType={'ONWARD'}
              />
            </>
          )}
        {props?.data?.orderAmount?.flightBasedBreakUp?.length > 1 && (
          <>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '16px 8px'
              }}>
              <Typography
                variant='subtitle2'
                sx={{ fontSize: '12px', lineHeight: '16px', color: 'grey.400' }}>
                PRODUCT
              </Typography>
              <Typography
                variant='subtitle2'
                sx={{
                  fontSize: '12px',
                  lineHeight: '16px',
                  color: 'grey.400'
                }}>
                AMOUNT
              </Typography>
            </Box>
            <Divider />
            <CustomTableAccordion
              label={onwardTable[0]?.label}
              tableData={onwardTable}
              value={onwardTable[0]?.value ?? 'N/A'}
              callback={(event: any, isExpanded: boolean) =>
                handleAccordionChange('onwardExpand', isExpanded)
              }
              tempCondition={true}
              expand={accordionStates?.onwardExpand}
              flightType={'ONWARD'}
            />
            {!accordionStates?.onwardExpand && <Divider />}
            <CustomTableAccordion
              label={returnTable?.[0]?.label}
              tableData={returnTable}
              value={returnTable?.[0]?.value ?? 'N/A'}
              callback={(event: any, isExpanded: boolean) =>
                handleAccordionChange('returnExpand', isExpanded)
              }
              expand={accordionStates?.returnExpand}
              flightType={'RETURN'}
            />
            {!accordionStates?.returnExpand && <Divider />}
          </>
        )}
        <Divider />
        {
          <CustomTableAccordion
            label={SMBChargesData?.[0]?.label}
            tableData={SMBChargesData}
            value={SMBChargesData?.[0]?.value ?? 'N/A'}
            callback={(event: any, isExpanded: boolean) =>
              handleAccordionChange('smbOnwardExpand', isExpanded)
            }
            expand={accordionStates?.smbOnwardExpand}
          />
        }
      </div>

      {sourceTable?.map((obj: any, index: number) => (
        <>
          {obj?.label?.includes('Total') && (
            <>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '16px 34px'
                }}>
                <Box
                  sx={{
                    flexGrow: 1,
                    display: 'flex',
                    justifyContent: 'center'
                  }}>
                  <Typography variant='subtitle2'>{obj?.label}</Typography>
                </Box>
                <OATypography variant='subtitle2'>{obj?.value ?? 'N/A'}</OATypography>
              </Box>
              <Divider />
            </>
          )}
          {index < sourceTable?.length &&
            index >= sourceTable?.length - ((addOn?.length > 0 && coinDiscount) ? 4 + addOn?.length : (coinDiscount || addOn) ? (4 + (addOn?.length - 1)) : 3) &&
            !obj?.label?.includes('Total') && (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '16px 34px'
                  }}>
                  <Box display='flex' alignItems='center'>
                    <Typography variant='body2' sx={{ color: obj?.success && 'success.main' }}>
                      {obj?.label}
                    </Typography>
                    {obj?.label?.includes('Cancellation Cover') && <Link to={`/apps/orders/insurance/${cancellatonCover?.supplierId ?? odsSelfLinks?.orderId}/activity-log?group=false?flightId=${props?.data?.id}`} className='link-primary fw-bolder' style={{ marginLeft: '5px' }}>
                      View Policy
                    </Link>}
                  </Box>
                  <OATypography variant='body2' sx={{ color: obj?.success && 'success.main' }}>
                    {obj?.label?.includes('discount') ? `- ${obj?.value}` : obj?.value ?? 'N/A'}
                  </OATypography>
                </Box>
                <Divider />
              </>
            )}
        </>
      ))}
      {props?.data?.orderAmount?.paymentSummary?.refundSummary && (
        <>
          <Box sx={{ padding: '16px' }}>
            <OATypography variant='subtitle2'>Refund details</OATypography>
            {(!orgCode || orgCode?.toLowerCase()?.includes('niyo')) && (
              <Box
                display='flex'
                height='40px'
                borderRadius='10px'
                alignItems='center'
                justifyContent='start'
                margin='16px 0px 0px 0px'
                sx={{
                  backgroundColor:
                    props?.data?.orderAmount?.paymentSummary?.refundSummary?.refundCoin > 0
                      ? 'success.light'
                      : '#FFE5E2'
                }}>
                {props?.data?.orderAmount?.paymentSummary?.refundSummary?.refundCoin > 0 ? (
                  <>
                    <SuccessIcon style={{ marginLeft: '10px' }} />
                    <Box display='flex' alignItems='center'>
                      <OATypography
                        variant='subtitle2'
                        sx={{
                          fontSize: '12px',
                          marginLeft: '10px'
                        }}>
                        {props?.data?.orderAmount?.paymentSummary?.coinsEarned}
                      </OATypography>
                      <OATypography
                        variant='body2'
                        sx={{ fontSize: '12px', lineHeight: '16px', paddingLeft: '4px' }}>
                        Niyo Global Coins will be added back.
                      </OATypography>
                    </Box>
                  </>
                ) : (
                  <>
                    <ErrorIcon style={{ marginLeft: '10px' }} />
                    <OATypography
                      variant='body2'
                      sx={{ fontSize: '12px', lineHeight: '16px', marginLeft: '10px' }}>
                      No coins are being refunded for this trip
                    </OATypography>
                  </>
                )}
              </Box>
            )}
            <OrderInvoice tableData={refundTableData} loading={props?.isLoading} />
          </Box>
        </>
      )}
    </Box>
  );
}
